import LastLessonPlay from "https://framer.com/m/LastLessonPlay-6uE2.js"
import { getLastLesson } from "https://framer.com/m/progress-PbSt.js"

import { useAuth } from "https://framer.com/m/hooks-cRhB.js"
import React from "react"
import { addPropertyControls, ControlType, RenderTarget } from "framer"

/**
 * These annotations control how your component sizes
 *
 * @framerDisableUnlink
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight any-prefer-fixed
 */
export default function LastLessonContinue(props) {
    const { user, isLoggedIn, loading } = useAuth()

    const [data, setData] = React.useState({
        lesson: "Carregando...",
        courseSlug: "",
        link: "/#",
    })

    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    React.useEffect(() => {
        if (!isCanvas && user) {
            const get = async () => {
                try {
                    const { lesson, course, path } = await getLastLesson({
                        userId: user.uid,
                    })
                    setData({
                        lesson,
                        courseSlug: course,
                        link: path,
                    })
                } catch (err) {
                    console.log(err)
                }
            }

            get()
        }
    }, [])

    return <LastLessonPlay {...props} {...data} />
}
